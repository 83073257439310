import React from "react"
import styled from "styled-components"

const NormalInput = ({ typeInput, error, ...props }) => {
  const component =
    typeInput === "textarea" ? <TextArea {...props} /> : <Input {...props} />
  const renderError = error !== "" && <ErrorText>{error}</ErrorText>

  return (
    <InputWrapper>
      {component}
      {renderError}
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const Input = styled.input`
  border: 1px solid ${({ theme: appTheme }) => appTheme.colors.primary};
  padding: 15px 15px 15px 15px;
  border-radius: 30px;
`

const TextArea = styled.textarea`
  border: 1px solid ${({ theme: appTheme }) => appTheme.colors.primary};
  padding: 15px 15px 15px 15px;
  border-radius: 30px;
  height: 165px;
`

const ErrorText = styled.span`
  color: red;
  font-size: 0.85rem;
  margin-top: 5px;
`

export default NormalInput
