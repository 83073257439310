import axios from "axios"

import { unMask } from "remask"
import { emailServiceAPI } from "../commons"
import { successObject, errorObject } from "./treatment"

export const newContactUs = async body => {
  try {
    const response = await axios.post(
      `${emailServiceAPI}/contact_us`,
      buildContactUs(body)
    )
    return successObject(response)
  } catch (error) {
    if (!error.response) {
      return {
        data: {
          status: 500,
          message: "Serviço indisponível no momento",
        },
      }
    }

    return errorObject(error)
  }
}

const buildContactUs = ({ name, email, phone, message }) => ({
  name: name.value,
  email: email.value,
  phone: unMask(phone.value),
  message: message.value,
  provider: "jnnet",
})
