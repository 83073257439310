export const clearInputs = setFunctions => {
  clearInputsState(setFunctions, {
    value: "",
    error: "",
  })
}

export const clearInputsState = (setFunctions, defaultValues) => {
  setFunctions.forEach((item, index) => {
    setFunctions[index](defaultValues)
  })
}
