import React, { useState } from "react"
import styled from "styled-components"
import media from "styled-media-query"

import NormalButton from "../../buttons/Normal"
import NormalInput from "../../inputs/Normal"

import {
  handleChangeText,
  handleChangeTextMasked,
} from "../../../helpers/inputChanges"
import { newContactUs } from "../../../services/contact_us"
import { clearInputs } from "../../../helpers/clearForm"

const defaultValue = { value: "", error: "" }

const Form = () => {
  const [name, setName] = useState(defaultValue)
  const [email, setEmail] = useState(defaultValue)
  const [phone, setPhone] = useState(defaultValue)
  const [message, setMessage] = useState(defaultValue)

  const setErrors = item => {
    let setFunction =
      "set" +
      item.field.substring(0, 1).toUpperCase().concat(item.field.substring(1))
    return eval(`${setFunction}({ value: "", error: item.title })`)
  }

  const handleSend = async () => {
    const { data } = await newContactUs({ name, email, phone, message })

    if (data.status < 500 && data.errors) return data.errors.forEach(setErrors)

    clearInputs([setName, setEmail, setPhone, setMessage])

    if (data.status >= 500) {
      window.alert(data.message)
    } else {
      window.alert("Email enviado!")
    }
  }

  return (
    <FormWrapper>
      <TitleWrapper>
        <BoldTitleText>PREENCHA O FORMULÁRIO</BoldTitleText>
        <BoldTitleText>E FALE COM A GENTE.</BoldTitleText>
        <TitleText>(Todos os campos são obrigatórios)</TitleText>
      </TitleWrapper>

      <InputsWrapper>
        <NormalInput
          placeholder="Seu nome"
          onChange={event => handleChangeText(event, setName)}
          value={name.value}
          error={name.error}
        />
        <NormalInput
          placeholder="Seu e-mail"
          onChange={event => handleChangeText(event, setEmail)}
          value={email.value}
          error={email.error}
        />
        <NormalInput
          placeholder="Seu telefone"
          onChange={event =>
            handleChangeTextMasked(event, setPhone, [
              "(99) 9999-9999",
              "(99) 99999-9999",
            ])
          }
          value={phone.value}
          error={phone.error}
        />
        <NormalInput
          typeInput="textarea"
          placeholder="Sua mensagem"
          onChange={event => handleChangeText(event, setMessage)}
          value={message.value}
          error={message.error}
        />
      </InputsWrapper>

      <ButtonWrapper>
        <NormalButton onClick={handleSend} title="Enviar" />
      </ButtonWrapper>
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-color: yellow;
  border-radius: 20px;
  max-width: 450px;
  padding: 25px;
  background-color: white;

  -webkit-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.73);
  -moz-box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.73);
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.73);

  ${media.greaterThan("medium")`
    width: 450px;
    padding: 25px;
    background-color: white;
    position: absolute;
    right: 0px;
    bottom: -70px;
    z-index: 9999;
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`

const BoldTitleText = styled.p`
  color: ${({ theme: appTheme }) => appTheme.colors.primary};
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 25px;
`

const TitleText = styled.p`
  color: ${({ theme: appTheme }) => appTheme.colors.primary};
  font-size: 1.12rem;
  line-height: 25px;
`

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export default Form
