export const successObject = response => {
  return {
    success: true,
    status: response.status,
    data: response.data,
  }
}

export const errorsResponse = error => {
  try {
    const response = JSON.parse(error.request.response)
    return response.errors
  } catch {
    return { error: "undefinied" }
  }
}

export const errorObject = error => {
  return {
    data: {
      success: false,
      status: error.request.status,
      errors: errorsResponse(error),
    },
  }
}
