import React from "react"
import styled from "styled-components"

const NormalButton = ({ title, ...props }) => (
  <Button type="button" {...props}>
    <Text>{title}</Text>
  </Button>
)

const Button = styled.button`
  background-color: ${({ theme: appTheme }) => appTheme.colors.primary};
  border: none;
  padding: 15px 20px;
  border-radius: 25px;
  width: 255px;
`

const Text = styled.p`
  color: white;
  font-size: 1.12rem;
`

export default NormalButton
