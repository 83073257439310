import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import Form from "./Form"
import Informations from "./Informations"

const backgroundPath = require("../../../images/contact/background.png")

const FirstRow = () => (
  <FormRowWrapper>
    <LimitContent>
      <Informations />
      <Form />
    </LimitContent>
  </FormRowWrapper>
)

const FormRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-image: url(${backgroundPath});
  min-height: 627px;

  ${media.greaterThan("medium")`
    height: 627px;
  `}
`

const LimitContent = styled.div`
  max-width: 1080px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  padding: 20px;

  ${media.greaterThan("medium")`
    padding: 0;
  `}
`

export default FirstRow
