import React from "react"

import Layout from "../layout/Layout"
import FirstRow from "../components/Contact/FirstRow/FirstRow"
// import SecondRow from "../components/Contact/SecondRow/SecondRow"

import Warning from "../components/Warning/Warning"

const Contato = () => (
  <Layout title="Contato">
    <FirstRow />
    <Warning />
  </Layout>
)

export default Contato
