import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const Informations = () => (
  <InformationsWrapper>
    <TitleWrapper>
      <TitleText>CENTRAL DE</TitleText>
      <BoldTitleText>ATENDIMENTO</BoldTitleText>
    </TitleWrapper>

    <InfosWrapper>
      <Text>JNNET</Text>
      <Text>Rua Pedro Nolasco</Text>
      <Text>115, Centro</Text>
      <Text>João Neiva - ES</Text>
      <Text>0800 500 4650</Text>
    </InfosWrapper>
  </InformationsWrapper>
)

const InformationsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-color: red;
  justify-content: center;
  margin-bottom: 20px;

  ${media.greaterThan("medium")`
    margin-bottom: 0px;
  `}
`

const TitleText = styled.p`
  font-size: 2rem;
  color: white;

  ${media.greaterThan("medium")`
    font-size: 3rem;
  `}
`

const BoldTitleText = styled.p`
  font-size: 2rem;
  font-weight: bold;
  color: white;
  ${media.greaterThan("medium")`
    font-size: 3rem;
  `}
`

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.3;
  color: white;

  ${media.greaterThan("medium")`
    font-size: 1.3rem;
  `}
`

const TitleWrapper = styled.div`
  margin-bottom: 20px;

  ${media.greaterThan("medium")`
    margin-bottom: 50px;
  `}
`

const InfosWrapper = styled.div`
  margin-bottom: 25px;
`

export default Informations
